.guest-cards {
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
}
.guest-footer {
    background: #FFFFFF;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.12);
    padding: 12px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.guest-button {
    background: #009AE0;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 0;
    text-align: center;
    color: #FFFFFF;
    border: none;
    outline: none;
    margin: 0 32px;
    cursor: pointer;
    height: 44px;
}



/************************** card-container **************************/
.card-container {
    display: flex;
    background: #FFFFFF;
    flex-direction: column;
    margin: 16px 8px 0;
}
.card-container-top {
    background: #FFE0E4;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    padding: 16px;
}
.card-container-top-Text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
}
.card-container-top-Image {
    display: flex;
    justify-content: center;
}
.card-container-bottom {
    margin: 16px;
    font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 20px;
color: rgba(0, 0, 0, 0.87);

}

@media (max-width: 330px){
    .card-container-top-Text {
        font-size: 15px;
        line-height: 22px;
    }
    .card-container-bottom{
        font-size: 12px;
    }
}