body {
  margin: 0;
  /* font-family: "Lato",
    sans-serif; */
  font-family: 'Inter',
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}